// colors
$white: #FFF;
$black-soft: #443C3C;
$gray-dark: #8B7474;
$gray-light: #E8E1E1;
$gray-light-2: #EDEDED;
$red: #D0021B;

// mixins
@mixin flex-col {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-flow: column;
}

@mixin flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

@mixin flex-row {
  display: flex;
  flex-direction: row;
  width: 100%;
}

@mixin flex-row-wrap {
  display: flex;
  flex-flow: row wrap;
  width: 100%;
}

@mixin flex-column {
  display: flex;
  flex-direction: column;
}

@mixin flex-column-center {
  display: flex;
  flex-flow: column;
  align-items: center;
}

@mixin input {
  font-size: 20px;
  font-family: "Roboto";
  font-weight: 300;
  background: #F7F4F4;
  color: #848484;
  border-radius: 30px;
  border: 0;
  padding: 12px 28px;
  width: 400px;
  max-width: 80%;
}

@mixin btn-submit {
  font-family: "Roboto";
  font-size: 20px;
  color: #CF021A;
  background-color: #FFFFFF;
  border: 1.5px solid #CF021A;
  border-radius: 30px;
  padding: 10px;
  width: 250px;
  max-width: 80%;

  &:hover {
    cursor: pointer;
    background-color: #CF021A;
    color: #FFFFFF;
    transition: .2s;
  }
}

@mixin portal-content-spacing {
  margin-left: 580px;
  padding-top: 30px;
  padding-bottom: 30px;
  padding-right: 30px;
}

@mixin portal-content-title {
  font-family: 'Roboto';
  color:#1F0F0F;

};