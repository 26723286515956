.subPlans__reccur {
  display: flex;
  width: 100%;
  justify-content: center;
  margin-bottom: 40px;
}

.subPlans__reccur__btn--active {
  border-radius: 20px;
  background-color: #3c2222;
  padding: 8px 15px;
}

.subPlans__reccur__btn__txt--active {
  font-family: "Dm Sans";
  font-weight: 600;
  color: #fff;
}

.subPlans__reccur__btn--default {
  border-radius: 20px;
  background-color: #fff;
  padding: 8px 15px;
  border: 1px solid #3c2222;
}

.subPlan__featureTxt {
  font-family: "Dm Sans";
  font-weight: 600;
  color: #3c2222;
}

.subscription__freeTrial__txt {
  color: #f96273;
  font-family: "Dm Sans";
}

.subPlan__card {
  border-radius: 15px !important;
  width: 400px;
}

.card-header {
  border-top-right-radius: 15px !important;
  border-top-left-radius: 15px !important;
}

.subPlans__reccur__btn__txt--default {
  font-family: "Dm Sans";
  font-weight: 600;
  color: #3c2222;
}

.subPlans__reccur__btn--monthly {
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;

  &:hover {
    cursor: pointer;
    opacity: 0.8;
  }
}

.subPlans__reccur__btn--annual {
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;

  //   margin-left: 20px;

  &:hover {
    cursor: pointer;
    opacity: 0.8;
  }
}
.subPlan__ccLite__usedUp--container {
}

.subPlan__ccLite__usedUpTxt {
  color: #f86273;
  margin-top: 100px;
  font-family: "Dm Sans";
}
