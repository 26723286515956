.AddAvailability {
  display: flex;
  flex-flow: row;
}

/* VIEW AVAILABILITY */
.availability-view-content-container {
  display: flex;
  flex-direction: column;
  padding-top: 50px;
  margin-left: 425px;
  // margin: 25px;
}

.availaibility-tag-heading-container {
  // Tag Heading (i.e. Handle, Status, Field)
  strong {
    font-family: "Roboto";
    font-weight: 400;
    color: #8b7474;
  }

  // Tag Value (i.e. hadiaamir021, Paralegal, Landlord or Tenant)
  h5 {
    font-family: "Roboto";
    font-weight: 400;
    font-size: 1.1em;
  }
}

.editHours__hoursList {
  display: flex;
  flex-flow: row wrap;
}

.availability-view-content-button-container {
  margin-left: 30px;

  /* EDIT PROFILE BUTTON */
  .availability-view-content-button {
    margin-right: 2.5em;
    margin-bottom: 20px;
    font-size: 16px;
    background: #a21022;
    border: 0px;
    width: 140px;
    height: 55px;
    border-radius: 8px;
    font-family: "Sarabun";
    font-weight: 700;
    color: #fff;
    background: #d0021b;
    box-shadow: 0 0 4px 0 rgba(211, 185, 185, 0.99);

    &:hover {
      background: #d6394a;
      box-shadow: 0 0 10px 0 rgba(211, 185, 185, 0.99);
      transition: 0.3s;
      cursor: pointer;
    }

    &:focus {
      outline: none;
    }
  }
}

/* EDIT AVAILABILITY */
.edit-avail-content-container {
  display: flex;
  flex-flow: column;
  margin: 25px;
  margin-right: 0;
  margin-left: 420px;
}

.edit-avail-form-container {
  display: flex;
  flex-flow: row wrap;
  // background: #FFFFFF;
  border-radius: 15px;
  height: auto;
  padding: 20px;
  margin-left: 160px;
  margin-right: 20px;
}

.edit-avail-input-container-1,
.edit-avail-input-container-2,
.edit-avail-input-container-3,
.edit-avail-input-container-4,
.edit-avail-input-container-5,
.edit-avail-input-container-6,
.edit-avail-input-container-7 {
  display: flex;
  flex-flow: column;
}

/* margin right on selected fields */
.edit-avail-input-container-1,
.edit-avail-input-container-3,
.edit-avail-input-container-5,
.edit-avail-input-container-7 {
  margin-right: 20px;
}

/* margin top on selected fields */
.edit-avail-input-container-3,
.edit-avail-input-container-4,
.edit-avail-input-container-5,
.edit-avail-input-container-6,
.edit-avail-input-container-7 {
  margin-top: 20px;
}

.edit-avail-day-text {
  font-family: "Sarabun";
  font-size: 18px;
  font-weight: 700;
}

.edit-avail-select-container {
  display: flex;
  flex-flow: row;
  margin-left: 20px;
}

.edit-avail-select-input-1 {
  margin-right: 20px;
}

/* EDIT AVAILABILITY BUTTON */
.edit-avail-update-button-container {
  margin-top: 4em;
  // margin-left: 200px;
  margin-bottom: 20px;
  display: flex;
  justify-content: flex-end;
}

.edit-avail-update-button {
  margin-top: 20px;
  font-size: 16px;
  background: #a21022;
  border: 0px;
  width: 240px;
  height: 55px;
  border-radius: 8px;
  font-family: "Sarabun";
  font-weight: 700;
  color: #fff;
  background: #d0021b;
  box-shadow: 0 0 4px 0 rgba(211, 185, 185, 0.99);
}

.edit-avail-update-button:hover {
  background: #d6394a;
  box-shadow: 0 0 10px 0 rgba(211, 185, 185, 0.99);
  transition: 0.3s;
  cursor: pointer;
}

.edit-avail-update-button:focus {
  outline: none;
}

.edit-avail-return-button {
  margin-top: 20px;
  font-size: 16px;
  border: 0px;
  width: 240px;
  height: 55px;
  border-radius: 8px;
  font-family: "Sarabun";
  font-weight: 700;
  color: #d0021b;
  background: rgb(255, 255, 255);
  box-shadow: 0 0 4px 0 rgba(211, 185, 185, 0.99);
}

.edit-avail-return-button:hover {
  background: rgb(248, 248, 248);
  box-shadow: 0 0 10px 0 rgba(211, 185, 185, 0.99);
  transition: 0.3s;
  cursor: pointer;
}

.edit-avail-return-button:focus {
  outline: none;
}

// create profile - availability
.law-portal-select-list-input-create-profile {
  outline: none;
  font-size: 16px;
  font-family: "Roboto";
  font-weight: 300;
  background: #ffffff;
  color: #111111;
  border-radius: 8px;
  border: 1px solid #d3b9b9;
  padding: 5px 20px;
  width: 150px;
  height: 40px;
}

.edit-avail-form-container-create-profile {
  display: flex;
  flex-flow: row wrap;
  background: #ffffff;
  border-radius: 15px;
  height: auto;
  padding: 30px 30px;
}

.edit-avail-select-container-create-profile {
  display: flex;
  flex-flow: row;
  margin-left: 20px;
}

.edit-avail-input-container-1-create-profile {
  margin-right: 50px;
}
