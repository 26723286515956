.Subscription {
  width: 100%;
  height: 100vh;
  background-color: #fff;
  position: absolute;
  display: flex;
  flex-flow: row;
  justify-content: center;
  padding: 100px;
}

.Subscription__Outer {
  display: flex;
  flex-flow: column;
}
.subscription__noPremiumLogin__content {
  padding: 0px !important;
  width: 600px;
}

.noPremiumLogin__content__title--dashboard,
.noPremiumLogin__content__subtitle--dashboard {
  padding-right: 150px;
}
.subscription__noPremiumLogin__content__pricing--free {
  width: 400px;
  background-color: #fff !important;
  border: 2px solid #d0021b;
}

.subscription__noPremiumLogin__content__pricing--free {
  width: 400px;
  background-color: #d0021b !important;
  // border: 2px solid #d0021b;
}

.subscription__noPremiumLogin__content__pricing--premium {
  width: 400px;
  height: 450px;

  background-color: #e2bf4b !important;
  // border-radius: 0 0 6px 6px;
}

.txtColor--free {
  color: #d0021b !important;
  // margin-bottom: 10px;
}

.noPremiumLogin__content__pricing__content__btn {
  background-color: #fff;
  border-radius: 8px;
  font-size: 16px;
  margin-top: 10px;
  width: 100%;
  padding: 15px;
  border: none;
  font-family: "DM Sans";
  font-weight: 600;
  color: #d0021b;

  &:hover {
    cursor: pointer;
    opacity: 0.95;
  }
}

.noPremiumLogin__content__pricing__content--dashboard {
  margin-top: 10px;
  margin-bottom: 10px;
}

.noPremiumLogin__homeBtn {
  position: absolute;
  right: 100px;
  font-size: 18px;
  font-family: "DM Sans";

  &:hover {
    cursor: pointer;
    opacity: 0.8;
  }
}

.subscription__currentPlanTxt {
  color: #d0021b;
}

.navbar__links__ccPremium,
.navbar__links__ccPremium--lite {
  margin-bottom: 30px;
  border: 1.5px solid #e8c30c;
  background-color: #fff;

  border-radius: 8px;
  padding: 15px 10px;
  height: fit-content;

  display: flex;
  flex-flow: column;
  justify-content: space-between;
}

.navbar__links__ccPremium--lite {
  border: none;
  background-color: #fff1f2;
}

.navbar__links__ccPremium--manage {
  border: none;
}
.navbar__links__ccPremium__icon {
  width: 25px;
}

.navbar__links__ccPremium__label {
  font-family: "DM Sans";
  margin-left: 10px;
  font-size: 18px;
  font-weight: 500;
  color: #5a5857;
}

.navbar__links__ccPremiumTxtWrap {
  display: flex;
  flex-flow: row;
  align-items: center;
  // justify-content: center;
  margin-left: 10px;
}
.navbar__links__ccFree__btn,
.navbar__links__ccPremium__btn,
.navbar__links__ccLite__btn {
  height: 50px;
  border-radius: 25px;
  width: 180px;
  margin-top: 10px;
  background-color: #e2bf4b;
  font-size: 16px;
  color: #ffffff;
  font-weight: 600;
  font-family: "DM Sans";
  border: none;
  &:hover {
    cursor: pointer;
    opacity: 0.8;
  }
  &:focus {
    outline: none;
  }
}

.navbar__links__ccLite__btn {
  background-color: #d0011c;
  color: #fff;
}

.navbar__links__ccFree__btn {
  background-color: #a8a8a8;
  color: #fff;
}

.card-header--ccLite {
  background-color: #d0011c;
  color: #fff;
}

.card-header--ccPremium {
  background-color: #e2bf4b;
  color: #fff;
}

.navbar__links__ccPremium__list {
  display: flex;
  flex-flow: column;
  align-items: flex-start;
  margin-left: 10px;
  margin-top: 7px;
}

.navbar__links__ccPremium__list__txt {
  font-family: "DM Sans";
  margin-bottom: 5px;
  color: #5a5857;
}

.noPremiumLogin__content__pricing__header {
  margin-bottom: 10px;
}
