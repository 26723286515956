.Contact {
  display: flex;
  flex-direction: row;
}

.contact-form-outer-container {
  margin: 25px;
  margin-top: 40px;
  margin-left: 580px;
}

.contact-header {
  display: flex;
  height: 55vh;
  background-image: linear-gradient(0deg, #ffffff 13%, #777777 88%);
  justify-content: center;
  flex-flow: column;
  align-items: center;
}

/*  */
.contact-form-title {
  font-family: "Roboto";
  font-weight: 700;
}

.contact-form-subtitle {
  color: #8b7474;
}

.contact-form-container {
  margin-top: 20px;
  display: flex;
  flex-flow: column;
  justify-content: center;
  background: #ffffff;
  // box-shadow: 0 0 20px -10px rgba(115, 115, 115, 0.5);
  border-radius: 8px;
  padding: 25px;
  width: 500px;
}

.contact-input {
  font-size: 18px;
  padding: 10px 20px;
  border-radius: 30px;
  border: none;
  background-color: #f2f2f2;
  color: #838383;
}

.contact-input:focus {
  outline: none;
}

.contact-form-names-container {
  display: flex;
  flex-flow: row;
}

.contact-form-names-item1 {
  margin-right: 15px;
}

.contact-form-names-item1,
.contact-form-names-item2,
.contact-form-email-container,
.contact-form-message-container {
  display: flex;
  flex-flow: column;
}

.contact-textarea:focus {
  outline: none;
}

.contact-textarea {
  font-size: 18px;
  padding: 10px 20px;
  border: 1px solid #d3b9b9;
  background-color: #ffffff;
  border-radius: 8px;
  height: 200px;
  resize: vertical;
  color: #222222;
}

.form-text {
  color: #333333;
  margin-bottom: 10px;
  margin-left: 10px;
}

.contact-form-email-container {
  margin-top: 10px;
}

.contact-form-message-container {
  margin-top: 10px;
}

/* CONTACT BUTTON */
.contact-form-send-button {
  margin-top: 2em;
  font-size: 16px;
  background: #a21022;
  border: 0px;
  width: 100%;
  height: 55px;
  border-radius: 8px;
  font-family: "Sarabun";
  font-weight: 700;
  color: #fff;
  background: #d0021b;
  box-shadow: 0 0 4px 0 rgba(211, 185, 185, 0.99);

  &:hover {
    background: #d6394a;
    box-shadow: 0 0 10px 0 rgba(211, 185, 185, 0.99);
    transition: 0.3s;
    cursor: pointer;
  }

  &:focus {
    outline: none;
  }
}

.contact-info-item2 h2 {
  font-family: "Sarabun";
  font-weight: 600;
  text-align: center;
  font-size: 2em;
  color: #4a4a4a;
  margin-bottom: 0;
}

.contact-info-item2 h3 {
  font-family: "Sarabun";
  font-weight: 400;
  text-align: center;
  font-size: 1.2em;
  color: #716a6a;
  line-height: 2em;
}

/*  */
.contact-form-outer-text {
  font-family: "Roboto";
  font-weight: 400;
  font-size: 20px;
  margin-bottom: 15px;
  color: #878787;
}

/*  */

@media screen and (max-width: 992px) {
  .contact-header-title {
    font-size: 3.5em;
    margin-bottom: 15px;
  }

  .contact-header-subtitle {
    font-size: 1.5em;
    padding-left: 1em;
    padding-right: 1em;
  }
}

@media screen and (max-width: 767px) {
  .contact-form-outer-container {
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;
    padding: 10px 0px 60px 0px;
    height: 100%;
  }

  .contact-form-names-container {
    display: flex;
    flex-flow: column;
  }

  .contact-form-names-item2 {
    margin-top: 20px;
  }

  .contact-form-names-item1 {
    margin-right: 0px;
  }

  .contact-form-send-button {
    margin-left: 40px;
    margin-right: 40px;
  }

  .contact-input {
    min-width: 310px;
  }

  .contact-header-title {
    font-size: 2em;
    padding-left: 0.5em;
    padding-right: 0.5em;
  }

  .contact-header-subtitle {
    font-size: 1.2em;
    padding-left: 1em;
    padding-right: 1em;
  }
}
