@import '../../../style/_variables.scss';


.NewEmployeeModal {

}

.new-emp-modal-container {
    // @include portal-content-spacing;
    // @include flex-row;
    // padding: 0px 25px 15px 30px;
    padding-left: 19px;
    margin-top: -20px;
}

.new-emp-modal-pg-title { 
    margin-bottom: 30px;
}

// .new-emp-modal-pg-line { 
//     margin-top: 10px;
//     margin-bottom: 10px;
//     min-width: 100%;
// }

// Top wrapper
.new-emp-modal-top-w {
    @include flex-row;
    // justify-content: space-evenly;
   
    // padding-right: 5px;
   
}

.new-emp-modal-top-w-profile-pic-w {
   @include flex-center;
    border: solid 1px #D4B9BA;
    border-radius: 8px;
    height: 135px;
    width: 180px;

    &:hover {
        border: solid 1px #3689FF;
        opacity: .5;
        cursor: pointer;
    }
}

.new-emp-modal-top-w-gen-info-w {
    @include flex-row-wrap;
    // margin-left: 10px;
    // justify-content: space-around;
}



.new-emp-modal-top-w-gen-info-name-w {
    @include flex-row;
    justify-content: space-evenly;
    // width: 360px;
}

.new-emp-modal-top-w-gen-info-contact-w {
    @include flex-row;
    justify-content: space-evenly;
    margin-top: 10px;
}


// Middle wrapper
.new-emp-modal-middle-w {
   @include flex-column;
   margin-top: 10px;
   padding-right: 20px;
}

.new-emp-modal-middle-w-occ-info-w {
    @include flex-row;
    justify-content: space-between;
}


// Bottom wrapper
.new-emp-modal-bottom-w {
    @include flex-column;
    // margin-top: 10px;
    // justify-content: space-between;
}

.new-emp-modal-bottom-w-other-info-w { 
    @include flex-row;
    justify-content: space-between;
    margin-top: 10px;
    width: 96%;
}



.new-emp-modal-uploaded-pic {
    height: 140px;
    width: 140px;
    padding-left: 10px;
    border-radius: 8px;
}

.modal-spinner-container {
    display: flex;
    flex-flow: column;
    align-items: center;
}

.modal-spinner-title {
    transition: ease-in;
    margin-top: 100px;
    font-weight: 300;
}



.modal-loading-spinner { 
    margin-top: 30px;
    height: 80px;
    width: 80px;
}



.exp-modal-outer-container {
    display: flex;
    flex-flow: column;
}

.exp-modal-form {
    display: flex;
    flex-flow: row;
}

.exp-modal-form-container-1 {
    display: flex;
    flex-flow: column;
    margin-right: 40px;
}

.exp-modal-form-container-2 {
    display: flex;
    flex-flow: column;
}


.exp-modal-item {
    margin-bottom: 20px;
}

/*  */

.creds-outer-container {
    display: flex;
    flex-flow: column;
}

// 

.modal-btn-group {
    display: flex;
    flex-flow: row;
    justify-content: center;
   margin-top: 30px;
}


.modal-btn-red {
    background-color: #D01D1B;
    padding: 10px 30px;
    color: #FFF;
    border: none;
    margin-right: 20px;
    border-radius: 8px;
    font-family: 'DM Sans';
    font-weight: 700;

    &:focus {
        outline: none;
    }

    &:hover {
        opacity: .8;
        cursor: pointer;
    }

}


.modal-btn-default {
    background-color: rgb(235, 235, 235);
    padding: 10px 33px;
    color: #D01D1B;
    border: none;
    border-radius: 8px;
    font-family: 'DM Sans';
    font-weight: 700;

    &:focus {
        outline: none;
    }

    &:hover {
        opacity: .8;
        cursor: pointer;
    }

}
.delete-emp-title {
    font-size: 26px;
    padding-left: 50px;
    padding-right: 50px;
    text-align: center;
}

.delete-emp-modal-container {
    display: flex;
    flex-flow: column;
    align-items: center;
}

.delete-emp-img {
    width: 100px;
    height: 100px;
    margin-bottom: 30px;
    border-radius: 50%;
}