.profile-card-p {

	box-shadow: 0 0 20px -10px rgba(115,115,115,0.50);
  height: 329px;
  width: 800px;
	// margin-right: 200px;
	// margin-left: 200px;
  margin-top: 2em;

  background: #FFFFFF;
  border: 1px solid #D8D8D8;
  border-radius: 4px;

  @media screen and (max-width: 992px) {
    height: 329px;
    width: 600px;
  }
 

}

.profile-card-image  {
  text-align: center;
}

.profile-card-image img {
  /* width: 150px; */
  margin-left: 2em;
  margin-top: .5em;
  margin-bottom: 2.3em;
}

.profile-card-img {
  width: 140px;
  height: 140px;
}

.profile-card-image p {
  font-family: Open Sans;
  font-size: 16px;
  color: #9B9B9B;
  margin-top: 20px;
}

/**/

.profile-card-title {
  margin-top: 2em;
  margin-right: 0em;
  margin-left: 42px;
}


.profile-card-title h3 {
  font-family: 'Roboto';
  font-weight: 700;
  font-size: 28px;
  color: #4A4A4A;
  margin-right: 0px;
}
 
.profile-card-title p { 
  font-family: 'Roboto';
  font-size: 20px;
  color: #715F5F;
}

.profile-card-title span {
  text-overflow: ellipsis;
  font-size: 16px;
  font-weight: 300;
  color: #715F5F;
}

/**/
.profile-card-info {
  margin-right: 0em;
  margin-left: 3em;
}

.profile-card-info h4 {
  font-family: 'Roboto';
  font-weight: 400;
  font-size: 18px;
  color: #4A4A4A;
}



.profile-card-info p { 
  font-family: 'Roboto';
  font-size: 16px;
  color: rgb(126, 121, 121);
  margin-left: 3em;
}

.profile-card-title hr {
  width: 500px;
  @media screen and (max-width: 992px) {
    width: 200px;
  }
  @media screen and (max-device-width:767px){
    display: none;
    
  }
}


/**/
.profile-card-button {
  display: flex;
  justify-content: flex-end;
  margin-top: .5em;
  // background-image: linear-gradient(270deg, #F13F3F 0%, #AF1B1B 100%);
  border-radius: 4px;
  // width: 400px;
  height: 68px;
  @media screen and (max-width: 992px) {

  }
  @media screen and (max-device-width:767px){
 
    
  }
}

.profile-card-button button {
  float: right;
  background: #D0021B;
  /* box-shadow: 0 1px 6px 0 rgba(0,0,0,0.50); */
  font-family: 'Roboto';
  font-weight: 700;
  border: none;
  border-radius: 24px;
  font-size: 14px;
  color: #FFFFFF;
  text-align: center;
  cursor: pointer;
  padding: 13px 27px 13px 27px;
  margin-top: -2em;
  margin-right: 1em;
  /* margin-left: 20em; */
  @media screen and (max-width: 992px) {

  }
  @media screen and (max-device-width:767px){
  
    
  }
}


.profile-card-button button:hover {
  background: #B80219;
  transition: .3s;
}


.card-info {
  display: flex;
  flex-flow: column;  
}

.card-p {
	padding: 20px 35px 11px 10px;
	display: flex;
  /*flex-flow: row wrap;*/
}


/**/


.profile-card-button-mobile {
  display: none;
}

/* 
 */

 .cc-trusted {
  font-family: 'Roboto';
  font-weight: 700;
  font-size: 11.5px;
  color: #CC2727;
 }

 .fa-award {
  color: #CC2727;
 }

 .default-pic-background {
   background: #D8D8D8;
   margin-left: 3em;
   margin-top: 1em;
   height: 140px;
   width: 140px;
 }


/* On screens that are 992px or less, set the background color to blue */
@media screen and (max-width: 992px) {

  .profile-card-p{
    margin-right: 50px;
    margin-left: 50px;
  }

  .profile-card-button button {
    padding: 13px 27px 13px 27px;
    margin-left: 12em;
    margin-right: 0em;
    margin-top: -3em;
  }

}

@media screen and (max-device-width:767px){
  .profile-fields {
    display: none;
  }


.profile-card-p {
  // margin-right: 40px;
  margin-left: 10px;
  height: 180px;
  width: 95%;
}


.card-p {
  margin-top: 2em;
  padding: 0px 15px 0px 15px;
  display: flex;
  flex-flow: row;
  height: 250px;
}

.cc-trusted {
  display: none;
}

.profile-card-image {
  display: flex;
  flex-flow: row;
  margin-right: 2em;
}

.profile-card-image img, .default-pic-background {
  width: 100px;
  height: 100px;
  margin-left: 5px;
} 

.profile-card-image p {
  margin-top: 35px;
  margin-left: 15px;
}

.profile-card-button {
  // display: none;
}

.profile-card-button button {
    padding: 13px 27px 13px 27px;
    margin-left: 0em;
    margin-right: 0em;
    margin-top: 0em;
    
}

/**/


.profile-card-title {
  margin-top: 0;
  padding-top: 0;
  margin-right: 0em;
  margin-left: 0px;
}


.profile-card-title h3 {
  margin-top: 0;
  font-size: 18px;
  color: #4A4A4A;
  margin-right: 0px;
}
 
.profile-card-title p { 
  font-family: Open Sans;
  font-size: 14px;
  color: rgb(192, 182, 182);
}

/**/
.profile-card-info {
  margin-right: 0em;
  margin-left: 0em;
  display: none;
}

.profile-card-info h4 {
  font-size: 14px;
  color: #4A4A4A;
}


.profile-card-info p { 
  // font-family: 'Roboto';
  // font-size: 13px;
  // color: #9B9B9B;
  // margin-left: 0em;
  display: none;
}

.profile-card-button-mobile {
  display: block;
  margin-top: -.5em;
  margin-left: 6.5em;

}


}








