.client-info {
  max-width: 33%;
  min-width: 33%;
  // width: 500px;
  height: calc(110vh - 50px);
  background-color: #fff;
  margin-left: 43px;
  border-radius: 13px;
  margin-right: 25px;
  padding: 37px;
}

.client-info__contact-info__email,
.client-info__contact-info__phone,
.client-info__contact-info__service {
  display: flex;
  flex-flow: row;
  align-items: center;
}

.clientInfo__letterNameWrap {
  display: flex;
  flex-flow: row;
  align-items: center;
}

.client-info__contact-info {
  margin-left: 20px;
}

.client-info__contact-info__service {
  margin-top: 20px;
}

.client-info__contact-info__email__txt {
  margin-bottom: 20px;
  margin-top: 15px;
}

.client-info__contact-info__email__txt,
.client-info__contact-info__phone__txt {
  font-weight: 400;
  font-size: 16px;
  color: #3b1212;
  font-family: "DM Sans";
  margin-left: 11px;
}

.client-info__divider {
  width: 100%;
}

.client-info__message {
  display: flex;
  flex-flow: column;
  // width: 100%;
  // overflow-x: scroll;
  // width: 400px !important;
  word-wrap: break-word;
}

.client-info__message__txt {
  font-weight: 400;
  font-size: 16px;
  color: #3b1212;
  font-family: "DM Sans";
}

.client-info__message__txt--invalid {
  font-weight: 700;
  font-size: 18px;
  color: #3b1212;
  font-family: "DM Sans";
}

.client-info__contact-info__email__txt--invalid,
.client-info__contact-info__phone__txt--invalid {
  box-shadow: 3px 2px 20px 0 rgba(123, 123, 123, 0.5);
  border-radius: 6.5px;

  width: 200px;
  height: 20px;
  margin-left: 10px;
}

.client-info__contact-info__email--invalid {
  display: flex;
  flex-flow: row;
  align-items: center;
  margin-bottom: 20px;
}

.client-info__upgrade-btn {
  height: 50px;
  border-radius: 8px;
  background-color: #d0021b;
  color: #fff;
  font-weight: 600;
  font-family: "DM Sans";
  font-size: 16px;

  width: 100%;
  border: none;
  margin-top: 20px;

  &:hover {
    cursor: pointer;
    opacity: 0.8;
  }

  &:focus {
    outline: none;
  }
}
