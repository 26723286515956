.accountDeactivated {
  background-color: #fff;
  height: 100vh;
  position: absolute;
  width: 100%;
  display: flex;
  flex-flow: row;
  justify-content: center;
  align-items: center;
}

.accountDeactivated__content {
  flex-flow: row;
  flex-flow: column;
}

.Dashboard {
  display: flex;
  flex-flow: column;
  justify-content: flex-start;

  margin-left: 234px;
  padding: 20px 20px 60px;
  // width: 100%;
  height: 100%;

  h1 {
    padding: 20px;
    font-family: "Roboto";
    font-weight: 300;
    font-size: 36px;
    color: #8b7474;
  }
}

.no-profile-dashboard-container {
  // padding-left: 270px;
  padding-left: 100px;
  padding-right: 100px;
}

.dashboard-row-1 {
  display: flex;
  flex-flow: row;
  justify-content: space-between;
  margin-bottom: 1.2em;

  div:first-child,
  div:nth-child(2),
  div:nth-child(3) {
    margin-right: 1.2em;
  }
}

.dashboard-social-profile-card {
  h2 {
    font-family: "DM Sans";
    padding: 15px;
    padding-top: 10px;
    padding-bottom: 10px;
    font-size: 16px;
    font-weight: 700;
    // text-align: left;
    color: #3e2222;
  }
}

.dashboard-row-2 {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;

  .dashboard-social-profile-card {
    margin-right: 1.2em;
  }
}

.dashboard-row-3 {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;

  span {
    margin-top: 1em;
    color: #c7c7c7;
  }
}

// PROFILE CARD
.dashboard-profile-card {
  display: flex;
  justify-content: center;
  flex-flow: row;
  align-items: center;
  background-color: #ffffff;
  padding: 30px 25px 30px 25px;
  border-radius: 10px;
  box-shadow: 0 0 20px -10px rgba(0, 0, 0, 0.5);
  flex-grow: 1;

  .profile-view-image {
    width: 140px;
    height: 100px;
    border-radius: 50%;
  }
}

.dashboard-profile-card-text-container {
  margin-left: 25px;
}

.dashboard-profile-card-status {
  font-family: "Sarabun";
  font-weight: 700;
  font-size: 24px;
  color: #252525;
  text-align: left;
}

.dashboard-profile-card-field {
  font-family: "Roboto";
  font-weight: 300;
  font-size: 18px;
  color: #8c7475;
  text-align: left;
}

.dashboard-profile-card-field-container {
  max-width: 340px;
}

.dashboard-profile-card-address {
  margin-top: 5px;
  font-family: "Roboto";
  font-weight: 300;
  font-size: 18px;
  color: #949494;
  text-align: left;
}

//

//   Profile/Reviews Count Card
.dashboard-view-count-card {
  padding: 20px;
  display: flex;
  flex-flow: column;
  justify-content: center;
  background-color: #ffffff;
  box-shadow: 0 0 20px -10px rgba(0, 0, 0, 0.5);
  border-radius: 8px;
  height: auto;
  // max-height: 200px;
  flex-grow: 2;
  // width: 100%;
  text-align: center;
  // margin-bottom: 2.5em;
  // margin-right: 3em;

  span:first-child {
    font-family: "Roboto";
    font-weight: 700;
    font-size: 48px;
    color: #252525;
  }

  span:nth-child(2) {
    font-family: "Roboto";
    color: #949494;
  }
}

//	Client Connection Count Card
.dashboard-connections-count-card {
  padding: 20px;
  display: flex;
  flex-flow: column;
  justify-content: center;
  background-image: linear-gradient(222deg, #f10707 0%, #d61b4d 100%);
  box-shadow: 0 0 20px -10px rgba(0, 0, 0, 0.5);
  border-radius: 8px;
  flex-grow: 2;

  text-align: center;
  span:first-child {
    font-family: "Roboto";
    font-weight: 700;
    font-size: 48px;
    color: #ffffff;
  }

  span:nth-child(2) {
    font-family: "Roboto";
    color: #ffffff;
  }
}

// Share card
.dashboard-share-card-container {
  display: flex;
  flex-flow: column;
  flex-grow: 2;

  div:first-child {
    margin-bottom: 1.2em;
  }
}

//	Social Profile
.dashboard-social-profile-card {
  padding: 20px;
  display: flex;
  flex-flow: column;
  justify-content: center;
  background-color: #ffffff;
  box-shadow: 0 0 20px -10px rgba(0, 0, 0, 0.5);
  border-radius: 18px;
  height: 350px;
  flex-grow: 1;
  text-align: center;

  img {
    margin-bottom: 1em;
    width: 130px;
    display: block;
    margin-left: auto;
    margin-right: auto;
  }

  span {
    font-family: "Roboto";
    color: #949494;
    padding: 15px;
  }
}

/* VIEW BUTTON */
.dashboard-social-profile-button {
  display: block;
  margin-right: auto;
  margin-left: auto;
  margin-bottom: 20px;
  margin-top: 20px;
  font-size: 16px;
  background: #a21022;
  border: 0px;
  width: 140px;
  height: 40px;
  border-radius: 8px;
  font-family: "Sarabun";
  font-weight: 700;
  color: #fff;
  background: #d0021b;
  box-shadow: 0 0 4px 0 rgba(211, 185, 185, 0.99);

  &:hover {
    background: #d6394a;
    box-shadow: 0 0 10px 0 rgba(211, 185, 185, 0.99);
    transition: 0.3s;
    cursor: pointer;
  }

  &:focus {
    outline: none;
  }
}

.dashboard-share-card {
  padding: 20px;
  display: flex;
  flex-flow: column;
  justify-content: center;
  background-color: #ffffff;
  box-shadow: 0 0 20px -10px rgba(0, 0, 0, 0.5);
  border-radius: 18px;
  min-height: 120px;
  height: 100%;
  text-align: center;

  h3 {
    font-family: "DM Sans";
    padding: 15px;
    padding-top: 10px;
    padding-bottom: 10px;
    font-size: 16px;
    font-weight: 700;
    text-align: left;
    color: #3e2222;
  }

  span {
    font-family: "DM Sans";
    color: #a58c8d;
    // padding: 15px;
    font-size: 38px;
    font-weight: 700;
    text-align: left;
    padding-left: 15px;
    padding-right: 15px;
  }
}

table {
  border-collapse: column;
}

/*header text*/
th {
  font-size: 18px;
  font-weight: 400;
  color: #4a4a4a;
}

/*inside text*/
td {
  font-size: 16px;
  font-weight: 300;
  /*color: #666666;*/
}

th,
td {
  text-align: left;
}

tr:nth-child(odd) {
  background: rgba(149, 57, 57, 0.1);
}

/*  */

.table {
  border-radius: 10px;
  overflow: hidden;
  perspective: 1px;
  border-collapse: collapse;
  table-layout: fixed;
  width: 100%;
  white-space: nowrap;

  margin-top: 32px;
  background: #ffffff;
  box-shadow: 0 0 20px -10px rgba(115, 115, 115, 0.5);
}

.table thead th {
  border-bottom: none;
}

.table td {
  border: none;
  font-family: "Sarabun";
  font-weight: 200;
  text-align: left;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  padding: 15px 20px 10px 20px;
  width: 100%;
}

/*workflow table - header*/
.table th {
  width: 100%;
  font-family: "Sarabun";
  font-weight: 900;
  padding-top: 15px;
  padding-bottom: 15px;
  padding-left: 20px;
  text-align: left;
  background-color: #fff;
  border: 0;
}

.table thead tr {
  display: flex;
  flex-flow: row;
  width: 635px;
}

.table th:last-child,
.table td:last-child {
  text-align: right;
  padding-right: 20px;
}

.table tbody {
  max-height: calc(55vh - 225px);
  display: block;
  overflow-y: auto;
  overflow-x: hidden;
}

.table thead {
  display: block;
  overflow-x: hidden;
}

.table tbody tr {
  display: flex;
}

/*  */

/*  */
/* #auth-padding {
	padding-left: 100px;
	padding-top: 2em;
	padding-bottom: 2em;
} */

#auth-title {
  font-family: "Open Sans";
  font-weight: 700;
  font-size: 42px;
  color: #443c3c;
}

#auth-sub-title {
  font-family: "Lato";
  font-weight: 300;
  font-size: 21px;
  color: #8b7474;
}

#auth-header-title {
  font-family: "Lato";
  font-weight: 200;
  font-size: 20px;
  color: #a29191;
}

.display-4 {
  font-family: "Open Sans";
  font-weight: 900;
  color: #252525;
}

.mb-4 {
  font-family: "Open Sans";
  font-weight: 700;
  color: #252525;
}

.dash-info-card1 {
  border-radius: 20px;
  padding: 1.5em;
  color: #252525;
  margin-bottom: 3em;
  box-shadow: 0 0 20px -10px rgba(115, 115, 115, 0.5);
}

.dash-info-card2 {
  border-radius: 20px;
  padding: 1.5em;
  color: #252525;
  box-shadow: 0 0 20px -10px rgba(115, 115, 115, 0.5);
}

.profile-buttons {
  display: flex;
  align-items: baseline;
  margin-bottom: 2.5em;
  margin-top: 2em;
}

.profile-actions {
  display: flex;
  text-decoration: none;
  justify-content: center;
  margin-right: 2em;
  padding: 1em;
  width: 100%;
  border-radius: 20px;
  box-shadow: 0 6px 10px 0 rgba(0, 0, 0, 0.16), 0 1px 12px 0 rgba(0, 0, 0, 0.18),
    0 3px 5px 0 rgba(0, 0, 0, 0.2);
}

.profile-action-color1 {
  background-image: linear-gradient(-46deg, #f5515f 0%, #9f041b 100%);
}
.profile-action-color2 {
  background-image: linear-gradient(-45deg, #fad961 0%, #f76b1c 100%);
}
.profile-action-color3 {
  background-image: linear-gradient(134deg, #3023ae 0%, #c86dd7 100%);
}
.profile-action-color4 {
  background-image: linear-gradient(90deg, #26ae3c 0%, #abff9e 100%);
}

.profile-action-color1:hover {
  background-image: linear-gradient(-45deg, #f39fa6 0%, #9f041b 100%);
}
.profile-action-color2:hover {
  background-image: linear-gradient(-45deg, #ffe997 0%, #f76b1c 100%);
}
.profile-action-color3:hover {
  background-image: linear-gradient(134deg, #3023ae 0%, #d5abdc 100%);
}
.profile-action-color4:hover {
  background-image: linear-gradient(
    90deg,
    #26ae3c 0%,
    rgba(171, 255, 158, 0.46) 100%
  );
}

.fa-briefcase,
.fa-user-circle,
.fa-graduation-cap,
.fa-calendar-check {
  color: #fff;
  font-size: 1.5em;
  margin-right: 0.5em;
}

.profile-actions h4 {
  font-family: Open Sans;
  font-weight: 600;
  font-size: 1.2em;
  color: #fff;
}

/*  */

.dashboard-row-1-card-1,
.dashboard-row-1-card-2 {
  display: flex;
  flex-flow: row;
  background-color: #ffffff;
  padding: 30px;
  height: 130px;
  width: 100%;
  border-radius: 18px;
}

.dashboard-row-1-card-2 {
  justify-content: flex-start;
}

.dashboard-row-1-card-1-location {
  margin-left: 10px;
}

.dashboard-row-1-card-2 {
  // margin-right: 30px;
  background-color: #bd2c2c;
}

.dashboard-row-1-card-2-image {
  height: 110px;
}

.dashboard-row-1-card-2-title-container {
  display: flex;
  flex-flow: column;
  justify-content: center;
  font-family: "DM Sans";
  margin-left: 20px;

  .dashboard-row-1-card-2-title {
    font-size: 20px;
    color: #ffffff;
    font-weight: 700;
  }

  .dashboard-row-1-card-2-message {
    font-size: 16px;
    color: #ffffff;
    font-weight: 400;
  }
}

.dashboard-row-1-card-2 {
  justify-content: flex-start;
}

.dashboard-row-1-card-1-location {
  margin-left: 10px;
}

.dashboard-row-1-card-2 {
  // margin-right: 30px;
  background-color: #bd2c2c;
}

.dashboard-row-1-card-2-image {
  height: 110px;
}
