.SubNavbar {
  width: 320px;
  height: 100%;
  background-color: #f3f3f3;
  flex-shrink: 0;
  position: fixed;
  margin-left: 234px;
  a {
    text-decoration: none;
  }

  @media screen and (max-width: 992px) {
    // width: 100px;
  }
}

.sub-navbar-container {
  display: flex;
  justify-content: flex-start;
  padding: 10px;
  background-color: #e2e2e2;
  border-radius: 8px;
  margin: 20px 10px;
}

.sub-navbar-title {
  font-family: "Roboto";
  font-weight: 300;
  font-size: 24px;
  color: #cf021a;
}

.sub-navbar-link {
  height: 82px;
  border-bottom: 1px solid #d8d8d8;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-flow: row;
  padding: 10px;

  &.active {
    border-right: 4px solid #d0021b;
  }

  &:hover {
    cursor: pointer;
    background-color: #e9e9e9;
    text-decoration: none;
  }
}

.sub-navbar-icon {
  width: 22px;
}

.sub-navbar-icon-container {
  margin-right: 20px;
  margin-left: 10px;
}

.sub-navbar-text-container {
  display: flex;
  flex-flow: column;
}

.sub-navbar-text-title {
  font-family: "Roboto";
  font-weight: 400;
  color: #1f2023;
  font-size: 16px;
}

.sub-navbar-text-subtitle {
  font-family: "Roboto";
  color: #adadad;
  font-size: 14px;
  margin-bottom: 0;
}
