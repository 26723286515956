@import "../../../style/variables";

.NavBar {
  background-color: #ffffff;
  height: 100vh;
  width: 234px;
  border-right: 1.4px solid $gray-light;
  flex-shrink: 0;
  padding-top: 60px;
  position: fixed;
}

.sidebar {
  background-color: #ffffff;
  height: 100%;
  width: 234px;
  border-right: 1.4px solid $gray-light;
  flex-shrink: 0;
  padding-top: 60px;
  position: fixed;
  overflow-y: scroll;
  padding-bottom: 200px;
}

.navbar-logout-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 30px 0px;
  border-top: 1.4px solid $gray-light;
  // border-bottom: 1.4px solid $gray-light;
}

.fa-sign-out-alt {
  color: $gray-light;
  font-size: 35px;

  &:hover {
    cursor: pointer;
    color: #cf021a;
  }
}

.navbar-logo-container {
  display: flex;
  justify-content: center;
  margin-bottom: 60px;

  img {
    // width: 50px;
  }
}

.navbar-links-container {
  @include flex-col;
}

.navbar-make-appt-btn {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-family: "Roboto";
  font-weight: 400;
  border-radius: 6px;
  background: #c52d29;
  color: #ffffff;
  border: none;
  height: 45px;
  width: 85%;
  padding-right: 15px;
  padding-left: 15px;

  margin-bottom: 80px;

  &:hover {
    background: #d6394a;
    box-shadow: 0 0 10px 0 rgba(211, 185, 185, 0.99);
    transition: 0.3s;
    cursor: pointer;
  }

  &:focus {
    outline: none;
  }
}

.navbar-link {
  @include flex-row;
  justify-content: space-evenly;
  align-items: center;
  width: 180px;
  height: 38px;
  // border-radius: 8px;
  margin-bottom: 35px;
  // background-color: rgb(226, 226, 226);

  // img {
  //   width: 60%;
  // }

  &.selected {
    // background-color: $red;
    color: #c52c28;
    background: rgba(238, 66, 66, 0.15);
    border-radius: 8px;
  }

  &:hover {
    // background: rgba(236, 202, 202, 0.15);
    // border-radius: 23px;
    text-decoration: none;
    // border: 1px solid $red;
    transition: 0.15s;
  }
}

.navbar-icon-default {
  color: #3e2222;
}

.navbar-link-text {
  color: #3e2222;
  font-weight: 700;
  font-family: "Sen";
}

.navbar__txt--default {
  color: #9094a1;
  font-weight: 700;
  font-family: "Sen";
}

.navbar__logout__btn {
  background-color: #f3f4f5;
  padding: 10px 20px;
  display: flex;
  justify-content: space-between;
  // width: 100%;
  border-radius: 8px;
  margin-left: 25px;
  margin-right: 25px;

  &:hover {
    cursor: pointer;
    opacity: 0.8;
  }
}

.main__logout__btn {
  position: absolute;
  right: 0px;
  top: 20px;
  background-color: #3c2222;
}

.main__logout__btn__txt {
  color: #fff;
  margin-right: 10px;
}

// SIDEBAR
.sidebar__content {
  display: flex;
  flex-flow: column;
  justify-content: space-between;
  height: 100%;
}

.sidebar__content__links {
  display: flex;
  flex-flow: column;
  align-items: center;
}

.sidebar__content__links__txt--default,
.sidebar__content__links__txt--active {
  color: #9094a1;
  font-weight: 700;
  font-family: "Sen";
}

.sidebar__content__links__txt--active {
  color: #3e2222;
}

.navbar-link-text {
  font-size: 16px;
}

.navbar-logout-icon {
  &:hover {
    cursor: pointer;
    stroke: rgb(235, 62, 79);

    text-decoration: none;
    // border: 1px solid $red;
    transition: 0.15s;
  }
}

// appt
.appt-container {
  width: 100%;
}

.appt-tooltip {
}

.appt-tooltip {
  position: relative;

  padding: 12px 24px;
  color: white;
  background-color: black;
  font-size: 12.8px;
  border-radius: 10px;
  transition: all 0.3s;
  opacity: 0;
  visibility: hidden;
}

.navbar__links__ccPremium {
  margin-bottom: 30px;
  border: 1.5px solid #e8c30c;

  border-radius: 8px;
  padding: 15px 10px;
  height: fit-content;

  display: flex;
  flex-flow: column;
  justify-content: space-between;
}

.navbar__links__ccPremium--manage {
  border: none;
  display: flex;
  flex-flow: column;
  align-items: center;
}
.navbar__links__ccPremium__icon {
  width: 25px;
}

.navbar__links__ccPremium__label {
  font-family: "DM Sans";
  margin-left: 10px;
  font-size: 18px;
  font-weight: 500;
  color: #5a5857;
}

.navbar__links__ccPremiumTxtWrap {
  display: flex;
  flex-flow: row;
  align-items: center;
  // justify-content: center;
  margin-left: 10px;
}

.navbar__links__ccPremium__btn {
  height: 50px;
  border-radius: 25px;
  width: 180px;
  margin-top: 10px;
  background-color: #e8c30c;
  font-size: 16px;
  color: #ffffff;
  font-weight: 600;
  font-family: "DM Sans";
  border: none;
  &:hover {
    cursor: pointer;
    opacity: 0.8;
  }
  &:focus {
    outline: none;
  }
}

.navbar__links__ccPremium__list {
  display: flex;
  flex-flow: column;
  align-items: flex-start;
  margin-left: 10px;
  margin-top: 7px;
}

.navbar__links__ccPremium__list__txt {
  font-family: "DM Sans";
  margin-bottom: 5px;
  color: #5a5857;
}
