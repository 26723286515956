.SocialProfile {
    display: flex;
    flex-flow: row wrap; 
    height: 100vh;
    @media screen and (max-width: 992px) {
        display: block;
    }
    @media screen and (max-device-width:767px){
    
      
    }
}

.social-profile-header-container {
    display: flex;
    flex-flow: row; 
    justify-content: center;
    padding-left: 30px;
    padding-top: 30px;
    @media screen and (max-width: 992px) {
        display: block;
        flex-flow: row wrap; 
    }
    @media screen and (max-device-width:767px){
    
      
    }
}

.social-profile-view-image {
    // margin-top: 4em;
    width: 160px;
    height: 160px;
    border-radius: 50%;
}

// 
.social-profile-header-text-container {
    display: flex;
    flex-flow: column;
    // justify-content: center;
    margin-left: 3em;
    margin-top: 1em;
    @media screen and (max-width: 992px) {
        display: block;
        flex-flow: row wrap; 
        margin-left: 0em;
        margin-top: 2em;
    }
    @media screen and (max-device-width:767px){
    
      
    }
}

.social-profile-name {
    font-family: 'Roboto';
    color: #443C3C;
    font-size: 2em;
    font-weight: 700; 
}

.social-profile-law-status {
    font-family: 'Roboto';
    font-size: 1.5em;
    color: #716A6A;
    font-weight: 300; 
}

// 
.social-profile-website-links-container {
    display: flex;
    flex-flow: row;
    margin-top: 1em;
    
    a:first-child {
        margin-right: 1em;
    }

    a:first-child:hover {
      text-decoration: none;
      background-color: rgb(235, 252, 255);
      color: #443C3C;
    }

    a:last-child:hover {
        text-decoration: none;
        background-color: rgb(235, 245, 255);
        color: #443C3C;
      }
}

.social-profile-website-link {
    display: flex;
    color: #443C3C;
    padding: 10px;
    border: solid 1px #AFAFAF;
    border-radius: 4px;
    width: 120px;
    
    img {
        margin-right: .5em;
    }

    
}


// 

.social-profile-contact-container {
    border: solid 1px #D0021B;
    border-radius: 2px;
    padding: 20px;
    margin-left: 7em;
    width: 500px;
    height: 350px;
    @media screen and (max-width: 992px) {
       margin-top: 50px;
       margin-left: 0em;
    }
    @media screen and (max-device-width:767px){
        width: 300px;
        height: 100%;
    }
}

.social-profile-contact-success-container {
    border: solid 1px #4CD964;
    display: flex;
    justify-content: center;
}

.sp-contact-msg-container {
    display: flex;
    justify-content: center;
    flex-flow: column;
    text-align: center;
    margin-top: 20px;
    @media screen and (max-width: 992px) {
       margin-top: 50px;
       margin-left: 0em;
    }
    @media screen and (max-device-width:767px){
        width: 300px;
        height: 100%;
    }
}

.sp-contact-success-msg-txt {
    font-family: 'Roboto';
    font-weight: 400;
    font-size: 22px;
    color: #4CD964;
    margin-top: 26px;
    margin-bottom: 47px;
}

.sp-contact-info-msg-txt {
        font-family: 'Roboto';
        font-weight: 400;
        font-size: 16px;
        color: #222222;
        margin-left: 50px;
        margin-right: 50px;
}


.social-profile-contact-title-container {
    display: flex;
    flex-flow: row;
    margin-bottom: .8em;

    strong {
        color: #D0021B;
        margin-left: 1em;
    }
}

.social-profile-contact-hours-text { 
    
}

.social-profile-contact-hours-container {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    

    span {
        font-family: 'Roboto';
        font-weight: 300;
        color: #9C9C9C;
    }
    hr {
        width: 50px;
    }

   
}

.social-profile-contact-hours-text-container {
    display: flex;
    flex-flow: row;
    justify-content: center;
    align-items: center;
    margin-right: .6em;

    span {
        font-size: .9em;
    }
}


/* CONTACT BUTTON */
.social-profile-contact-button {
    margin-top: 20px;
    font-size: 18px;
    background: #a21022;
    border: 0px;
    width: 100%;
	height: 60px;
    border-radius: 4px;
    font-family: "Sarabun";
    font-weight: 700;
    color: #fff;
	background: #D0021B;
    box-shadow: 0 0 4px 0 rgba(211,185,185,0.99);
    
    &:hover {
        background: #D6394A;
        box-shadow: 0 0 10px 0 rgba(211,185,185,0.99);
        transition: .3s;
        cursor: pointer;
    }

    &:focus {
        outline: none;
    }
}


// 
.social-profile-content-container {
    margin-top: 3em;

    span {
        font-family: "Sarabun";
        font-weight: 700;
        font-size: 1.2em;
    }
}

.social-profile-content-1 {
    margin-bottom: 50px;
}

.social-profile-content-1-title-container {
    display: flex;
    flex-flow: column;
    margin-bottom: 1em;

    div {
        display: flex;
        flex-flow: row;
        align-content: baseline;
    }

    img {
        margin-top: 8px;
    }

    hr {
        min-width: 500px;
        width: 100%;
        @media screen and (max-device-width:767px){
            min-width: 200px;
        }
    }
}


.social-profile-content-1-body {
    display: flex;
    flex-flow: column;
    // justify-content: flex-start;
    

    div {
        margin-left: 2em; 
    }

    span {
        color: rgb(83, 73, 73);
        font-size: 1.1em;
    }

    p {
        margin-left: 1em; 
    }
}
// 

// 
.social-profile-content-1-body-subtitle {
    display: flex;
    flex-flow: row;
    margin-bottom: 1em;
    
    img {
        margin-left: .8em;
    }

    
}

.social-profile-content-1-body-map {
    display: flex;
    flex-flow: row;
    margin-bottom: 1em;
    
    // img {
    //     margin-left: .8em;
    // }

    
}






//EDUCATION
.social-profile-content-education {
    margin-bottom: 100px;
}


.social-profile-content-edu-wrapper {
    display: flex;
    flex-flow: column;
    margin-left: 2em;   
}


.social-profile-content-education-title {
    display: flex;
    flex-flow: column;
    margin-bottom: 1em;

    div {
        display: flex;
        flex-flow: row;
        align-content: baseline;
    }

    img {
        margin-top: 8px;
    }

    hr {
        min-width: 500px;
        width: 100%;
        @media screen and (max-device-width:767px){
            min-width: 200px;
        }
    }
}


.social-profile-content-education-subtitle {
    display: flex;
    flex-flow: row;

    strong {
        color: #D0021B;
        font-size: 1.1em;
    }

    img {
        margin-right: 1.5em;
    }
    
} 

.social-profile-content-education-body-subtitle {
    display: flex;
    flex-flow: column;
    margin-bottom: 1em;

}

.social-profile-content-education-body { 
    display: flex;
    flex-flow: row wrap;
    align-items: baseline;
    margin-left: 4.5em;
    @media screen and (max-device-width:767px){
        flex-flow: column;
    }

    .social-profile-content-edu-deg-type {
        color: #443C3C;
    }

    .social-profile-content-edu-deg-major {
        color: #897F7F;
    }

    .social-profile-content-edu-uni-name {
        color: #806B6B;
        font-size: 16px;
    }

    
    .social-profile-content-edu-deg-date {
        font-family: 'Sarabun';
        font-weight: 300;
        color: #897F7F;
       
    }
}

.social-profile-content-education-body-item-2 {
    margin-left: 5em;
    @media screen and (max-device-width:767px){
        margin-left: 0;
    }
}


// 

.social-profile-content-bio-container {
    display: flex;
    flex-flow: row;
    justify-content: flex-start;
    align-items: center;

    img {
        width: 130px;
        height: 130px;
        @media screen and (max-device-width:767px){
            margin-bottom: 2em;
        }
    }

    p {
        color: rgb(138, 133, 133);
        margin-left: 2em;
        max-width: 600px;
        @media screen and (max-device-width:767px){
            margin-left: 0em;
        }
    }
    @media screen and (max-device-width:767px){
        flex-flow: column;
    }
}


//  INPUT FIELD - CONTACT FORM

.text-input-width {
    width: 140px;
    height: 40px;
   
}


.social-profile-booking-textarea {
    margin-top: 10px;
}

.sp-booking-date-container {
    display: flex;
    flex-flow: row;
    margin-top: 10px;
    width: 100%;
    justify-content: space-between;
    @media screen and (max-device-width:767px){
        flex-flow: column;
    }
}


.text-field-name {
    width: 155px;
    height: 40px;
}

.text-field-email {
    width: 295px;
    height: 40px;
    @media screen and (max-device-width:767px){
        width: 100%;
    }
}


.text-field-date {
    width: 170px;
    height: 40px;
}

.text-field-time {
    width: 135px;
    height: 40px;
}

// 
// .social-profile-content-1-office {
//     // display: flex;
//     // flex-flow: column;
//     margin-bottom: 100px;
//     background-color: purple;
//     // height: 500px;
// }



.address-sub-details {
    span {
        font-family: "Roboto";
        font-weight: 100;
        font-size: 1.2em;
        color: #716A6A;
    }
}


// 




.social-profile-contact-msg-container {
    border: solid 1px #D0021B;
    border-radius: 2px;
    padding: 20px;
    margin-left: 7em;
    width: 500px;
    height: 480px;
    @media screen and (max-width: 992px) {
        margin-left: 0em;
        margin-top: 2em;
    }
    @media screen and (max-device-width:767px){
        width: 300px;
        height: 100%;
    }
}


.social-profile-contact-book-button {
    margin-top: -30px;  
}

.text-field-msg {
    width: 455px;
    @media screen and (max-device-width:767px){
        width: 100%;
    }
}


.consent-txt { 
    margin-top: 1em;
    text-align: center;
    font-family: 'Roboto';
    font-size: .7em;
}

// input stuff
.input-container {
    display: flex;
    flex-flow: column;
    margin-top: 0;
}


// Service input


.service-input {
    outline: none;
    font-size: 16px;
    font-family: "Roboto";
    font-weight: 300;
    background: #FFFFFF;
    color: #111111;
    border-radius: 8px;
    border: 1px solid #D3B9B9;
    padding: 5px 20px;
    width: 130px;
    height: 40px;;
  }
  
//   .select-list-input:focus {
//     border: 1px solid #3689FF;
//   }
  
//   .select-list-input-small {
//     font-size: 13px;
//     font-family: "Roboto";
//     font-weight: 400;
//     color: #9b9b9b;
//     margin-top: 10px;
//     margin-left: 1em;
//   }


.rating-container {
    padding: 20px;
    display: flex;
    flex-flow: row;
    @media screen and (max-device-width:767px){
        flex-flow: column;
    }
}

.rating-txt {
    width: 650px;
    margin-left:40px;
    @media screen and (max-device-width:767px){
        width: 100%;
    }
}

.exp-img {
    width: 50px;
    height: 50px;
}

.navbar-customize {
    margin-right: -15px;
}


// sub items 
.social-profile-sub-labels {
    color: #8A8585;
}
