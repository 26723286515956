.Connections {
  display: flex;
  flex-flow: column;
  padding-left: 40px;
  height: 100%;
}

.connections__statsSection {
  display: flex;
  flex-flow: row;
  align-items: center;
  justify-content: space-between;
}

.connections__stats--profile {
  display: flex;
  flex-flow: column;
}
.connections__stats {
  display: flex;
  flex-flow: row;
}
.connections__stats__card {
  padding: 11px 20px;
  border-radius: 8px;
  background-color: #fff;
  width: fit-content;
  display: flex;
  flex-flow: row;
  align-items: center;
  margin-right: 26px;
}

.connections__stats__card__txt {
  font-size: 14px;
  margin-left: 10px;
  color: #3b1212;
  font-family: "Dm Sans";
}

.connections__stats__card__value {
  font-size: 18px;
  margin-left: 20px;
  font-weight: 500;
  color: #3b1212;
  font-family: "Dm Sans";
}

.connections__stats__title {
  margin-bottom: 6px;
  margin-left: 10px;
  font-size: 16px;
  font-family: "Sen";
  color: #3b1212;
  font-weight: 700;
}

//

.connections__content-container {
  display: flex;
  flex-flow: row;
  margin-top: 38px;
  height: 100%;
}

.clients-info {
  width: 40%;
}

// no connections
.connections__none {
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  height: 90vh;
}

.connections__none__txt {
  font-family: "DM Sans";
  font-size: 21px;
  color: #3b1212;
  width: 600px;
  text-align: center;
}

.connections__none__btn {
  display: flex;
  align-items: center;
  padding: 12px 19px;

  border: 1.5px solid #3b1212;
  border-radius: 8px;

  margin-top: 40px;

  &:hover {
    cursor: pointer;
    opacity: 0.8;
  }
}

.connections__none__btn__txt {
  font-family: "DM Sans";
  font-size: 18px;
  color: #3b1212;
  margin-right: 15px;
}

.connections__none__title {
  font-family: "DM Sans";
  font-size: 21px;
  color: #3b1212;
  font-weight: 600;
  padding: 20px;
}
