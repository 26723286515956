// @import 'reset';
@import "variables";

body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
  // background-color: $white;
  background-color: #efebeb;
  overflow-y: scroll;
}

// .Main {
//   display: flex;
//   flex-flow: row;
// }

.copyright {
  text-align: center;
  font-size: 0.9em;
  color: #6c757d;
  margin-top: 60px;
}

.law-firm-portal-pg-spinner-container {
  @include portal-content-spacing;
  @include flex-column-center;
  width: 100%;
}
