.ModalWrapper {
  //   width: 600px;
  height: 100%;
  @media only screen and (max-width: 768px) {
    width: auto;
  }
}

.modalWrapper__upgrade {
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.modalWrapper__upgrade__title {
  font-weight: 700;
  font-size: 21px;
  color: #3b1212;
  font-family: "DM Sans";
  margin-bottom: 30px;
}

.modalWrapper__INE__exampleBallot {
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  padding: 10px 15px;
}
