.Location {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;
    flex-flow: column;
    height: 90vh;
    @media screen and (max-width: 992px) {
        padding-top: 50px;
        padding-bottom: 40px;
    }
    @media screen and (max-device-width:767px){
        padding-top: 100px;
       
    }
}

.location-search-subtitle-msg { 
    margin-top: 2em;
    font-family: 'Roboto';
    color: #D0021B;
}

.Paralegal-Search, .Lawyer-Search {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;
    flex-flow: column;
    height: 90vh;
    @media screen and (max-width: 992px) {
        padding-top: 50px;
        padding-bottom: 40px;
    }
    @media screen and (max-device-width:767px){
        height: 100%;
    }
}

.gender-buttons-container {
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
}


// 
.Results {
    // display: flex;
    // padding: 20px;
    // display: block;
    // margin-left: auto;
    // margin-right: auto;
    // height: 90vh;
    padding-top: 50px;
    padding-bottom: 100px;
    height: 100%;
    width: 100%;
    // height: 10vh;
    background: #F7F5F5;
  
    @media screen and (max-width: 992px) {
        // padding-top: 50px;
        // padding-bottom: 40px;
    }
    @media screen and (max-device-width:767px){
        height: 100%;
    }
}

.result-container {
    display: flex;
    
    justify-content: center;
    align-items: center;
    flex-flow: column;
}



.location-search-title {
    font-family: 'Roboto';
    font-size: 32px;
    font-weight: 400;
    color: #303030;
    text-align: center;
    @media screen and (max-device-width:767px){
        font-size: 26px;
    
    }
}

.location-search-subtitle {
    font-family: 'Roboto';
    font-size: 19px;
    font-weight: 200;
    color: #8a8a8a;
    text-align: center;
    margin-bottom: 1em;
}


.location-search-button {
    margin-top: 2.5em;
    font-family: 'Roboto';
    font-size: 24px;
    color: #FFFFFF;
    text-align: left;
    background-image: linear-gradient(270deg, #E42254 3%, #DB3434 93%);
    border-radius: 8px;
    border: none;
    padding: 12px 25px;

    &:hover {
        cursor: pointer;
        opacity: .9;
    }

    &:focus{
        outline: none;
    }

    &:active {
        transform: translateY(1px);
    }
}


.fa-play {
    margin-bottom: 1px;
    margin-left: .5em;
    font-size: 13px;
}

.location-select-input {
    outline: none;
    font-size: 16px;
    font-family: "Roboto";
    font-weight: 300;
    background: #FFFFFF;
    color: #111111;
    border-radius: 8px;
    border: 1px solid #D3B9B9;
    padding: 10px 23px;
    width: 170px;
    height: 55px;;
    margin-top: 20px;
    margin-bottom: -20px;
  }
  
  .location-select-input:focus {
    border: 1px solid #3689FF;
  }
  

//   
.status-search-lawyer-button {
    margin-top: 20px;
    margin-right: 20px;
    height: 70px;
    width: 230px;
    border-radius: 8px;
    border: 1px solid rgb(153, 136, 136);
    background: #ffffff;;
    font-family: 'Roboto';
    font-weight: 400;
    font-size: 20px;
    color: rgb(155, 143, 143);
    text-align: center;

    &:hover {
        // background: #FFF5F5;
        border: 1px solid #FF0000;
        box-shadow: 0 0 20px -10px rgba(115,115,115,0.50);
        cursor: pointer;
        opacity: .9;
    }

    &:focus{
        outline: none;
        background: #FFF5F5;
        border: 1px solid #FF0000;
    }

    &:active {
        background: #FFF5F5;
        border: 1px solid #FF0000;
        box-shadow: 0 0 20px -10px rgba(115,115,115,0.50);
        cursor: pointer;
        opacity: .9;
        transform: translateY(1px);
    }

    @media screen and (max-device-width:767px){
        margin-right: 0px;
    }
    
}

// selected button 
.btn-selected { 
    background: #FFF5F5;
    border: 1px solid #FF0000;
    box-shadow: 0 0 20px -10px rgba(115,115,115,0.50);
    cursor: pointer;
    opacity: .9;
}


.search-button-question {
    font-size: 14px;
    margin-top: 10px;
    color: #8a8a8a;
    font-weight: 200;
}

.law-field-options {
    display: flex;
    justify-content: center;
    flex-flow: row wrap;
    padding-left: 200px;
    padding-right: 200px;

    @media screen and (max-width: 992px) {
        // justify-content: center;
        // padding-left: 100px;
        // padding-right: 70px;
        padding-left: 0;
        padding-right: 0;
    }

    @media screen and (max-device-width:767px){
        // height: 100%;
        // padding-right: 70px;
    }

}

// 

.result-search-subtitle-1 {
    margin-top:20px;
    text-align: center;
    color: #716A6A;
}

.result-search-subtitle-2 {
    text-align: center;
    color: #716A6A;
}




// Status Search
.location-search-button-container {
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
}

.result-empty-container {
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;
    height: 80vh;
}

.result-empty-form {
    margin-top: 2em;
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;
}

.get-update-txt {
    font-family: 'Roboto';
    font-size: 24px;
    font-weight: 300;
}
