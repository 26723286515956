.Login {
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
  background-color: #f6f0f0;

  img {
    width: 70px;
    margin-bottom: 30px;
  }
}

/* LOGIN TITLE */
.login-title {
  font-family: "Sarabun";
  font-weight: 700;
  font-size: 24px;
  color: #1f0f0f;
  text-align: center;
}

/* LOGIN SUBTITLE */
.login-subtitle {
  font-family: "Sarabun";
  font-size: 21px;
  color: #8b7474;
  text-align: center;
}

/* LOGIN FORM */
.login-form-container {
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
  width: 100%;
  max-width: 500px;
}

/* LOGIN INPUT FIELD */
// .login-input-email, .login-input-password {
//     outline: none;
// 	font-size: 20px;
// 	font-family: "Roboto";
// 	font-weight: 300;
// 	background: #F7F4F4;
// 	color: #848484;
// 	border-radius: 30px;
// 	border: 0;
// 	padding: 12px 28px;
//     width: 400px;
// }

// .login-input-email {
//     margin-bottom: 20px;
// }

/* LOGIN BUTTON */
.login-button {
  margin-top: 20px;
  font-size: 18px;
  background: #a21022;
  border: 0px;
  width: 300px;
  height: 70px;
  border-radius: 8px;
  font-family: "Sarabun";
  font-weight: 700;
  color: #fff;
  background: #d0021b;
  box-shadow: 0 0 4px 0 rgba(211, 185, 185, 0.99);

  &:hover {
    background: #d6394a;
    box-shadow: 0 0 10px 0 rgba(211, 185, 185, 0.99);
    transition: 0.3s;
    cursor: pointer;
  }

  &:focus {
    outline: none;
  }
}

/* COPYRIGHT TEXT */
.copyright-sym {
  text-align: center;
  font-size: 0.9em;
}

.password-input-container {
  margin-top: 20px;
}

.login-portal-input {
  outline: none;
  font-size: 16px;
  font-family: "Roboto";
  font-weight: 300;
  background: #ffffff;
  color: #111111;
  border-radius: 8px;
  border: 1px solid #d3b9b9;
  padding: 10px 23px;
  width: 300px;
  /* margin-right: 20px; */

  &:focus {
    border: 1px solid #3689ff;
  }
}

/* ERRORS */
.is-invalid {
  border: 1px solid red;
}

/* DONT HAVE AN ACCOUNT BOX */
.login-bottom-box {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 300px;
  height: 60px;
  background: #fcfafa;
  border: 1px solid #f1e8e8;
  border-radius: 8px;
  margin-top: 30px;
}

.login-bottom-box-txt {
  font-family: "Sarabun";
  color: #594747;
  font-weight: 700;
  margin-right: 5px;
}

.login-bottom-box-link {
  font-family: "Sarabun";
  color: #d0021b;
  font-weight: 700;
  text-decoration: none;

  &:hover {
    color: #d6394a;
  }
}
