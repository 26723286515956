.clients-list {
  display: flex;
  flex-flow: column;
  width: 77%;
  //   margin-top: 25px;
}

.clients-list__header {
  display: flex;
  flex-flow: row;
  justify-content: space-between;
}

.clients-list__header__title {
  font-weight: 700;
  font-family: "Sen";
  font-size: 20px;
}

.clients-list__header__dropdown {
  padding: 8px 15px;
  width: fit-content;
  background-color: #fff;
  border-radius: 4px;

  &:hover {
    cursor: pointer;
    opacity: 0.8;
  }
}

.clients-list__header__dropdown__txt {
  color: #9094a1;
  font-weight: 500;
  font-family: "DM Sans";
  margin-right: 7px;
  font-size: 14px;
}

.clients-list__list__card {
  display: flex;
  flex-flow: row;
  justify-content: space-between;
  padding: 15px 26px 15px 29px;
  background-color: #fff;
  border-radius: 8px;
  margin-bottom: 31px;
  border: 1.5px solid #fff;

  &:hover {
    cursor: pointer;
    opacity: 0.8;
    border: 1.5px solid #3b1212;
  }
}

.clients-list__list__card--active {
  border: 1.5px solid #3b1212;
}

.clients-list__list__card-left-container {
  display: flex;
  flex-flow: row;
}

.clients-list__list__card__profile-circle,
.clientInfo__client__letterWrap {
  width: 48.08px;
  height: 44px;
  background-color: #e9fbff;
  border-radius: 50%;

  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 24px;
}
.clientInfo__client__letterWrap {
  margin-right: 0px;
}
.clients-list__list__card__profile-circle__txt {
  font-weight: 400;
  font-family: "DM Sans";
  font-size: 18px;
  color: #3b1212;
}

.clients-list__list {
  margin-top: 42px;
}

.clients-list__list__card__name-and-time {
  display: flex;
  flex-flow: column;
}

.clients-list__list__card__name-and-time__name {
  font-weight: 500;
  font-size: 16px;
  margin-bottom: 3px;
  font-family: "DM Sans";
  color: #3b1212;
}

.clientInfo__lawyer__name,
.clientInfo__client__name {
  font-weight: 500;
  font-size: 16px;
  margin-bottom: 3px;
  font-family: "DM Sans";
  color: #3b1212;
  margin-left: 15px;
}

.clients-list__list__card__name-and-time__time-container {
  display: flex;
  flex-flow: row;
  align-items: center;
}

.clients-list__list__card__name-and-time__time__txt {
  margin-left: 5px;
  font-size: 14px;
  color: #9094a1;
}

.clients-list__list__card__messages {
  display: flex;
  flex-flow: row column;
  align-items: center;
  margin-right: 10px;
  margin-left: 30px;
}

.clients-list__list__card__messages__label {
  margin-left: 5px;
  font-size: 14px;
  color: #9094a1;
}

.clients-list__list__card-right-container {
  display: flex;
  flex-flow: row;
  align-items: center;
}

.clients-list__list__card__message-icon-container {
  display: flex;
  margin-right: 5px;
}

.clients-list__list__card__message-icon__notify {
  border-radius: 50%;
  width: 6px;
  height: 6px;
  background-color: #ff5353;
  border: 1px solid #fff;
  margin-left: -7px;
}

.clientList__lawyerArrowWrap {
  display: flex;
  flex-flow: row;
  align-items: center;
  margin-left: 30px;
}
.clientList__lawyer {
  margin-left: 24px;
}
.clientList__lawyer__img {
  width: 45px;
  height: 45px;
  border-radius: 50%;
}

.clientList__lawyer__resultTxt,
.clientList__lawyer__resultTxt--Bold {
  font-weight: 400;
  font-family: "DM Sans";
  font-size: 14px;
  margin-left: 14px;
  color: #3b1212;
}

.clientList__lawyer__resultTxt--Bold {
  font-weight: 600;
  margin-left: 0px;
}

.clients-list__list__card-left {
  display: flex;
  flex-flow: row wrap;
}

.clientsList__freeConnectLabel__txt {
  font-weight: 500;
  font-family: "DM Sans";
  font-size: 14px;
  color: #3b1212;
  margin-left: 10px;
}
