@import "../../../style/_variables.scss";

.Employees {
  @include flex-row;
}

.edit-employees-pg-content-container {
  @include portal-content-spacing;
  margin-left: 280px;
  width: 100%;
}

// Header
.edit-employees-pg-header-wrapper {
  @include flex-row;
  justify-content: space-between;
  align-items: center;
}

.edit-employees-pg-header-title {
  @include portal-content-title;
}

.edit-employees-pg-header-btn {
  /* margin-top: 3em; */
  // padding: 5px 15px 5px 15px;
  // TODO: space this button at the end
  height: 50px;
  // width: 50px;
  width: auto;
  color: $red;
  cursor: pointer;
  border-radius: 10px;
  font-size: 20px;
  background-color: transparent;
  font-weight: 200;
  border: 1px solid $red;
  box-shadow: 0 2px 20px -10px rgba(115, 115, 115, 0.5);
  outline: none;

  &:hover {
    transition: 0.3s;
    background-color: #fbf6f6;
    outline: none;
  }

  &:focus {
    outline: none;
  }
}

// Employees section
.edit-employees-pg-employees-wrapper {
  // background-color: lightcoral;
  width: 100%;
}

// Employees - card
.edit-employees-pg-employees-card {
  @include flex-row;
  align-items: center;
  font-family: "Roboto";
  padding: 8px 25px;
  // border: 1px solid #8C7475;
  background-color: white;
  border-radius: 8px;
  margin-top: 20px;
  height: 100px;
  // justify-content: center;
}

// Employees - card - desc - wrapper
.edit-employees-pg-employees-card > .edit-employees-pg-employees-card-desc-wp {
  // line-height: 25px;
  @include flex-column;
  line-height: normal;
  // width: 50%;
  justify-content: space-around;
}

.edit-employees-pg-employees-card-desc-wp {
  justify-content: space-between;
  width: 100%;
  padding-left: 20px;
  padding-right: 10px;
}

.edit-employees-pg-employees-card
  > .edit-employees-pg-employees-card-picture-wp {
  display: flex;
  width: 15%;

  // margin: 10px;
}

// Employees - card - picture - wrapper
.edit-employees-pg-employees-card-picture-wp {
  display: flex;
  align-items: center;
}

.edit-employees-pg-employees-card-picture {
  width: 65px;
  height: 65px;
  border-radius: 50%;
}

// Employees - card - desc - name - wrapper
.edit-employees-pg-employees-card-desc-name-wp {
}

.edit-employees-pg-employees-card-desc-name {
  // TODO: encapsulate
  font-family: "Roboto";
  color: #1f0f0f;
  font-size: 20px;
  //   text-align: center;
}

// Employees - card - desc - title - wrapper
.edit-employees-pg-employees-card-desc-title-wp {
  @include flex-row;
}

.edit-employees-pg-employees-card-desc-title-wp :nth-child(1) {
  color: #8c7475;
}

.edit-employees-pg-employees-card-desc-title-wp :nth-child(2) {
  margin-left: 10px;
  color: #362727;
}

// Employees - card - desc - services - wrapper
.edit-employees-pg-employees-card-desc-services-wp {
  @include flex-row;
}

.edit-employees-pg-employees-card-desc-services-wp :nth-child(1) {
  color: #8c7475;
}

.edit-employees-pg-employees-card-desc-services-wp :nth-child(2) {
  margin-left: 10px;
  color: #362727;
}

// Employees - card - desc - buttons - wrapper
.edit-employees-pg-employees-card-desc-buttons-wp {
  @include flex-row;
  align-items: center;
  width: 35%;
}

// Edit button
.edit-employees-pg-employees-card-desc-edit-btn {
  background-color: transparent;
  color: white;
  border-radius: 8px;
  height: 50px;
  width: 50px;
  border: none;

  &:hover {
    opacity: 0.8;
    cursor: pointer;
    outline: none;
  }

  &:focus {
    outline: none;
  }
}

.edit-employees-pg-employees-card-desc-delete-btn {
  background-color: transparent;
  color: white;
  border-radius: 8px;
  height: 50px;
  width: 50px;
  border: none;
  margin-left: 30px;

  &:hover {
    opacity: 0.8;
    cursor: pointer;
  }

  &:focus {
    outline: none;
  }
}
