

.new-lawfirm-modal-top-w-profile-pic-w {
    display: flex;
    justify-content: center;
    align-items: center;
     border: solid 1px #D4B9BA;
     border-radius: 8px;
     height: 140px;
     width: 160px;
     margin-right: 20px;
  
     &:hover {
         border: solid 1px #3689FF;
         opacity: .5;
         cursor: pointer;
     }
  }

  
.new-lawfirm-uploaded-pic {
    padding: 5px;
    min-height: 40px;
    max-width: 150px;
    border-radius: 8px;
   
}


.create-profile-form-container {
    margin: 25px;
    margin-left: 250px;
    background: #FFFFFF;
    box-shadow: 0 0 20px -10px rgba(115,115,115,0.50);
    border-radius: 5px;
    height: auto;
    padding: 30px 50px;
    max-width: 1000px;
}

/*  */
.law-field-input-small {
    width: 300px;
}
