@import '../../../style/_variables.scss';


.EditProfile {
  display: flex;
  flex-direction: row;
}



.profile-info-content-wrapper {
  margin-left: 500px;
}

.profile-edit-content-wrapper {
  margin-left: 530px;
}

.profile-info-container {
  display: flex;
  flex-direction: column;
  margin: 25px;

  h1 {
    // margin-left: 400px;
  }


}

.profile-info-tag-container {
  display: flex;
  flex-flow: row wrap;
}

.profile-view-image-container {
  display: flex;
  flex-flow: row wrap;
  margin-left: 3em;
  margin-bottom: 2.5em;

  .profile-view-image {
    width: 140px;
    height: 140px;
    border-radius: 50%;
  }
}


.edit-profile-image-active-container {
  display: flex;
  flex-flow: column;
}

.edit-profile-image-active {
  display: flex;
  flex-flow: row;
}




// PROFILE INFO TAG
.profile-info-tag-content-container {
  margin-bottom: 3em;
  margin-left: 2.5em;
  
  //  Tag Title (i.e. General, About Me)
  h6 {
    font-family: "Roboto";
    color: #D0021B;
    font-weight: 700;
    margin-bottom: 0;
  }

  hr {
    float: left;
  }

  .profile-info-tag-heading-container {

    // Tag Heading (i.e. Handle, Status, Field)
    strong {
      font-family: "Roboto";
      font-weight: 400;
      color: #8B7474;
    }

    // Tag Value (i.e. hadiaamir021, Paralegal, Landlord or Tenant)
    h5 {
      font-family: "Roboto";
      font-weight: 400;
      font-size: 1.1em;
    }

  }

}

.profile-info-edit-button-container {
  margin-left: 30px;
  /* EDIT PROFILE BUTTON */
  .profile-info-edit-button {
    margin-right: 2.5em;
    margin-bottom: 20px;
    font-size: 16px;
    background: #a21022;
    border: 0px;
    width: 140px;
    height: 55px;
    border-radius: 8px;
    font-family: "Sarabun";
    font-weight: 700;
    color: #fff;
    background: #D0021B;
    box-shadow: 0 0 4px 0 rgba(211, 185, 185, 0.99);

    &:hover {
      background: #D6394A;
      box-shadow: 0 0 10px 0 rgba(211, 185, 185, 0.99);
      transition: .3s;
      cursor: pointer;
    }

    &:focus {
      outline: none;
    }
  }

}

/* PROFILE UPDATE BUTTON */
.edit-profile-exit-button {
  margin-top: 20px;
  margin-right: 1.5em;
  font-size: 16px;
  border: 0px;
  width: 140px;
  height: 55px;
  border-radius: 8px;
  font-family: "Sarabun";
  font-weight: 700;
  color: #D0021B;
  background: #FFFFFF;
  box-shadow: 0 0 4px 0 rgba(211, 185, 185, 0.99);

  &:hover {
    background: rgb(252, 252, 252);
    color: #D0021B;
    box-shadow: 0 0 10px 0 rgba(211, 185, 185, 0.99);
    transition: .1s;
    cursor: pointer;
  }

  &:focus {
    outline: none;
  }
}

@media screen and (max-width: 992px) {
  .edit-profile-c2-input-field-container {
    display: flex;
    flex-flow: column;
    justify-content: space-around;
  }

  .edit-profile-c1-pic {
    padding-bottom: 30px;
  }
}

input[type="file"] {
  display: none;
}

.edit-profile-form-container {
  margin: 25px;
  background-color: #FFFFFF;
  border-radius: 15px;
  height: auto;
}

.edit-profile-image-container {
  display: flex;
  flex-flow: row wrap;
   margin-left: 20px;
}

.edit-profile-image-bottom-line {
  margin-top: 25px;
  margin-right: 20px;
  margin-bottom: 25px;
  border-bottom: solid 1px rgb(228, 218, 218);
}

.edit-profile-image {
  width: 140px;
  height: 140px;
  border-radius: 50%;
  border: 1px solid #E4DADA;

  &:hover {
    opacity: .7;
    cursor: pointer;
  }
}

.edit-profile-image-text {
  display: flex;
  flex-flow: column;
  justify-content: center;
  margin-left: 30px;
}

.edit-profile-image-title {
  font-family: "Sarabun";
  font-weight: 700;
}

.edit-profile-image-subtitle {
  font-family: "Sarabun";
  font-weight: 100;
}

.edit-profile-c1-text-group {
  display: flex;
  flex-flow: column;
  justify-content: flex-start;
  // align-items: center;
  // margin-left: 15px;
}

.edit-profile-c1-text {
  font-size: 20px;
  margin-left: 20px;
}

.edit-profile-c1-button {
  border: 1px solid #8B7474;
  border-radius: 10px;
  padding: 3px 20px;
  color: #8B7474;
  background: #FFFFFF;
}

.edit-profile-c1-button:focus {
  outline: none;
}

.edit-profile-c2-title,
.edit-profile-c3-title,
.edit-profile-c4-title {
  font-size: 20px;
  color: #D0021B;
}


.edit-profile-container-4 {
  margin-top: 40px;
}




.edit-profile-c2-input-group, .edit-profile-c3-bio-input {
  margin-top: 20px;
}

.edit-profile-c2-input-field-container {
  @include flex-row;
  justify-content: space-between;
  // padding: 30px 50px 30px 70px;
}

.edit-profile-c2-input-group-1,
.edit-profile-c2-input-group-2,
.edit-profile-c2-input-group-3,
.edit-profile-c3-input-group,
.edit-profile-c4-input-group {

  margin-right: 40px;
  margin-top: 20px;
}

.edit-profile-c4-input-field-container {
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
  margin-left: 20px;
}

/* Edit Profile - SUBMIT BUTTON */
.edit-profile-update-button-container {
  margin-top: 20px;
  margin-right: 25px;
  display: flex;
  justify-content: flex-end;
}

/* EDIT PROFILE BUTTON */
.edit-profile-update-button {
  margin-top: 20px;
  font-size: 16px;
  background: #a21022;
  border: 0px;
  width: 240px;
  height: 55px;
  border-radius: 8px;
  font-family: "Sarabun";
  font-weight: 700;
  color: #fff;
  background: #D0021B;
  box-shadow: 0 0 4px 0 rgba(211, 185, 185, 0.99);

  &:hover {
    background: #D6394A;
    box-shadow: 0 0 10px 0 rgba(211, 185, 185, 0.99);
    transition: .3s;
    cursor: pointer;
  }

  &:focus {
    outline: none;
  }
}

@media screen and (max-width: 992px) {
  .edit-profile-c2-input-field-container {
    display: flex;
    flex-flow: column;
    justify-content: space-around;
  }

  .edit-profile-c1-pic {
    padding-bottom: 30px;
  }
}

.default-pic { 
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-top: 16px;
  height: 80px;
}

.default-upload-pic-txt {
  font-family: 'Roboto';
  font-weight: 100;
  margin-top: 10px;
  color: #8B7474;
}

.default-pic-background {
  background: gray;
   width: 120px;
    height: 120px;
}

